import React from 'react';

import Styled, { ThemeProvider } from 'styled-components';

import theme, { eyebrowMixin, textShadowMixin } from '../../styles/theme';

const ThemePage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <h2>Colors</h2>
        {/* Teal colors */}
        <div>
          <div className="box teal--darkest">
            <span className="box__text teal--lightest">Darkest teal</span>
          </div>
          <div className="box teal--darker">
            <span className="box__text teal--lightest">Darker teal</span>
          </div>
          <div className="box teal--base">
            <span className="box__text teal--lightest">Base teal</span>
          </div>
          <div className="box teal--lighter">
            <span className="box__text teal--darkest">Lighter teal</span>
          </div>
          <div className="box teal--lightest">
            <span className="box__text teal--darkest">Lightest teal</span>
          </div>
          <div className="box teal--soft">
            <span className="box__text teal--darkest">Soft teal</span>
          </div>
        </div>
        {/* Dark blue colors */}
        <div>
          <div className="box dark-blue--darkest">
            <span className="box__text dark-blue--lightest">
              Darkest dark blue
            </span>
          </div>
          <div className="box dark-blue--darker">
            <span className="box__text dark-blue--lightest">
              Darker dark blue
            </span>
          </div>
          <div className="box dark-blue--base">
            <span className="box__text dark-blue--lightest">
              Base dark blue
            </span>
          </div>
          <div className="box dark-blue--lighter">
            <span className="box__text dark-blue--lightest">
              Lighter dark blue
            </span>
          </div>
          <div className="box dark-blue--lightest">
            <span className="box__text dark-blue--lightest">
              Lightest dark blue
            </span>
          </div>
          <div className="box dark-blue--soft">
            <span className="box__text dark-blue--darkest">Soft dark blue</span>
          </div>
        </div>
        {/* Light blue colors */}
        <div>
          <div className="box light-blue--darkest">
            <span className="box__text light-blue--lightest">
              Darkest light blue
            </span>
          </div>
          <div className="box light-blue--darker">
            <span className="box__text light-blue--lightest">
              Darker light blue
            </span>
          </div>
          <div className="box light-blue--base">
            <span className="box__text light-blue--lightest">
              Base light blue
            </span>
          </div>
          <div className="box light-blue--lighter">
            <span className="box__text light-blue--lightest">
              Lighter light blue
            </span>
          </div>
          <div className="box light-blue--lightest">
            <span className="box__text light-blue--lightest">
              Lightest light blue
            </span>
          </div>
          <div className="box light-blue--soft">
            <span className="box__text light-blue--darkest">
              Soft light blue
            </span>
          </div>
        </div>
        {/* Green colors */}
        <div>
          <div className="box green--darkest">
            <span className="box__text green--lightest">Darkest green</span>
          </div>
          <div className="box green--darker">
            <span className="box__text green--lightest">Darker green</span>
          </div>
          <div className="box green--base">
            <span className="box__text green--lightest">Base green</span>
          </div>
          <div className="box green--lighter">
            <span className="box__text green--lightest">Lighter green</span>
          </div>
          <div className="box green--lightest">
            <span className="box__text green--lightest">Lightest green</span>
          </div>
          <div className="box green--soft">
            <span className="box__text green--darkest">Soft green</span>
          </div>
        </div>
        {/* Yellow colors */}
        <div>
          <div className="box yellow--darkest">
            <span className="box__text yellow--lightest">Darkest yellow</span>
          </div>
          <div className="box yellow--darker">
            <span className="box__text yellow--lightest">Darker yellow</span>
          </div>
          <div className="box yellow--base">
            <span className="box__text yellow--lightest">Base yellow</span>
          </div>
          <div className="box yellow--lighter">
            <span className="box__text yellow--darkest">Lighter yellow</span>
          </div>
          <div className="box yellow--lightest">
            <span className="box__text yellow--darkest">Lightest yellow</span>
          </div>
          <div className="box yellow--soft">
            <span className="box__text yellow--darkest">Soft yellow</span>
          </div>
        </div>
        {/* Orange colors */}
        <div>
          <div className="box orange--darkest">
            <span className="box__text orange--lightest">Darkest Orange</span>
          </div>
          <div className="box orange--darker">
            <span className="box__text orange--lightest">Darker Orange</span>
          </div>
          <div className="box orange--base">
            <span className="box__text orange--lightest">Base Orange</span>
          </div>
          <div className="box orange--lighter">
            <span className="box__text orange--darkest">Lighter Orange</span>
          </div>
          <div className="box orange--lightest">
            <span className="box__text orange--darkest">Lightest Orange</span>
          </div>
          <div className="box orange--soft">
            <span className="box__text orange--darkest">Soft Orange</span>
          </div>
        </div>
        {/* Red colors */}
        <div>
          <div className="box red--darkest">
            <span className="box__text red--lightest">Darkest red</span>
          </div>
          <div className="box red--darker">
            <span className="box__text red--lightest">Darker red</span>
          </div>
          <div className="box red--base">
            <span className="box__text red--lightest">Base red</span>
          </div>
          <div className="box red--lighter">
            <span className="box__text red--lightest">Lighter red</span>
          </div>
          <div className="box red--lightest">
            <span className="box__text red--lightest">Lightest red</span>
          </div>
          <div className="box red--soft">
            <span className="box__text red--darkest">Soft red</span>
          </div>
        </div>
        {/* Gray colors */}
        <div>
          <div className="box gray--darkest">
            <span className="box__text gray--lightest">Darkest gray</span>
          </div>
          <div className="box gray--darker">
            <span className="box__text gray--lightest">Darker gray</span>
          </div>
          <div className="box gray--base">
            <span className="box__text gray--lightest">Base gray</span>
          </div>
          <div className="box gray--lighter">
            <span className="box__text gray--darkest">Lighter gray</span>
          </div>
          <div className="box gray--lightest">
            <span className="box__text gray--darkest">Lightest gray</span>
          </div>
          <div className="box gray--soft">
            <span className="box__text gray--darkest">Soft gray</span>
          </div>
        </div>
        <div>
          <div className="box black">
            <span className="box__text gray--lightest">Black</span>
          </div>
          <div className="box white">
            <span className="box__text gray--darkest">White</span>
          </div>
        </div>
        <span className="eyebrow">This is an eyebrow</span>
        <h2>Eyebrows</h2>
        <h2>Text shadow</h2>
        <div className="box teal--base text-shadow-demo">
          <span className="box__text gray--lightest">
            Here is text with a text shadow
          </span>
        </div>
      </Wrapper>
    </ThemeProvider>
  );
};

const Wrapper = Styled.div`
  font-family: ${({ theme }) => theme.primary.font.fontFamily};
  font-size: ${({ theme }) => theme.primary.font.sizes.normal.fontSize};
  padding: 12px;

  h2 {
    color: ${({ theme }) => theme.primary.colors.text.black};
  }

  .eyebrow {
    ${eyebrowMixin};
    display: block;
    margin-top: 48px;
    margin-bottom: -12px;
  }

  .box {
    min-width: 175px;
    min-height: 50px;
    display: inline-flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .box.teal--base {
    background-color: ${({ theme }) => theme.primary.colors.teal.base};
  }

  .box__text.teal--lightest {
    color: ${({ theme }) => theme.primary.colors.text.teal.lightest};
  }

  .box__text.teal--darkest {
    color: ${({ theme }) => theme.primary.colors.text.teal.darkest};
  }

  .box__text.dark-blue--lightest {
    color: ${({ theme }) => theme.primary.colors.text.darkBlue.lightest};
  }

  .box__text.dark-blue--darkest {
    color: ${({ theme }) => theme.primary.colors.text.darkBlue.darkest};
  }

  .box__text.light-blue--lightest {
    color: ${({ theme }) => theme.primary.colors.text.lightBlue.lightest};
  }

  .box__text.light-blue--darkest {
    color: ${({ theme }) => theme.primary.colors.text.lightBlue.darkest};
  }

  .box__text.green--lightest {
    color: ${({ theme }) => theme.primary.colors.text.green.lightest};
  }

  .box__text.green--darkest {
    color: ${({ theme }) => theme.primary.colors.text.green.darkest};
  }

  .box__text.yellow--lightest {
    color: ${({ theme }) => theme.primary.colors.text.yellow.lightest};
  }

  .box__text.yellow--darkest {
    color: ${({ theme }) => theme.primary.colors.text.yellow.darkest};
  }

  .box__text.orange--lightest {
    color: ${({ theme }) => theme.primary.colors.text.orange.lightest};
  }

  .box__text.orange--darkest {
    color: ${({ theme }) => theme.primary.colors.text.orange.darkest};
  }

  .box__text.red--lightest {
    color: ${({ theme }) => theme.primary.colors.text.red.lightest};
  }

  .box__text.red--darkest {
    color: ${({ theme }) => theme.primary.colors.text.red.darkest};
  }

  .box__text.gray--lightest {
    color: ${({ theme }) => theme.primary.colors.text.gray.lightest};
  }

  .box__text.gray--darkest {
    color: ${({ theme }) => theme.primary.colors.text.gray.darkest};
  }

  .box.teal--lighter {
    background-color: ${({ theme }) => theme.primary.colors.teal.lighter};
  }

  .box.teal--lightest {
    background-color: ${({ theme }) => theme.primary.colors.teal.lightest};
  }

  .box.teal--darker {
    background-color: ${({ theme }) => theme.primary.colors.teal.darker};
  }

  .box.teal--darkest {
    background-color: ${({ theme }) => theme.primary.colors.teal.darkest};
  }

  .box.teal--soft {
    background-color: ${({ theme }) => theme.primary.colors.teal.soft};
  }

  .box.dark-blue--darkest {
    background-color: ${({ theme }) => theme.primary.colors.darkBlue.darkest};
  }

  .box.dark-blue--darker {
    background-color: ${({ theme }) => theme.primary.colors.darkBlue.darker};
  }

  .box.dark-blue--base {
    background-color: ${({ theme }) => theme.primary.colors.darkBlue.base};
  }

  .box.dark-blue--lighter {
    background-color: ${({ theme }) => theme.primary.colors.darkBlue.lighter};
  }

  .box.dark-blue--lightest {
    background-color: ${({ theme }) => theme.primary.colors.darkBlue.lightest};
  }

  .box.dark-blue--soft {
    background-color: ${({ theme }) => theme.primary.colors.darkBlue.soft};
  }

  .box.light-blue--darkest {
    background-color: ${({ theme }) => theme.primary.colors.lightBlue.darkest};
  }

  .box.light-blue--darker {
    background-color: ${({ theme }) => theme.primary.colors.lightBlue.darker};
  }

  .box.light-blue--base {
    background-color: ${({ theme }) => theme.primary.colors.lightBlue.base};
  }

  .box.light-blue--lighter {
    background-color: ${({ theme }) => theme.primary.colors.lightBlue.lighter};
  }

  .box.light-blue--lightest {
    background-color: ${({ theme }) => theme.primary.colors.lightBlue.lightest};
  }

  .box.light-blue--soft {
    background-color: ${({ theme }) => theme.primary.colors.lightBlue.soft};
  }

  .box.green--darkest {
    background-color: ${({ theme }) => theme.primary.colors.green.darkest};
  }

  .box.green--darker {
    background-color: ${({ theme }) => theme.primary.colors.green.darker};
  }

  .box.green--base {
    background-color: ${({ theme }) => theme.primary.colors.green.base};
  }

  .box.green--lighter {
    background-color: ${({ theme }) => theme.primary.colors.green.lighter};
  }

  .box.green--lightest {
    background-color: ${({ theme }) => theme.primary.colors.green.lightest};
  }

  .box.green--soft {
    background-color: ${({ theme }) => theme.primary.colors.green.soft};
  }

  .box.yellow--darkest {
    background-color: ${({ theme }) => theme.primary.colors.yellow.darkest};
  }

  .box.yellow--darker {
    background-color: ${({ theme }) => theme.primary.colors.yellow.darker};
  }

  .box.yellow--base {
    background-color: ${({ theme }) => theme.primary.colors.yellow.base};
  }

  .box.yellow--lighter {
    background-color: ${({ theme }) => theme.primary.colors.yellow.lighter};
  }

  .box.yellow--lightest {
    background-color: ${({ theme }) => theme.primary.colors.yellow.lightest};
  }

  .box.yellow--soft {
    background-color: ${({ theme }) => theme.primary.colors.yellow.soft};
  }

  .box.orange--darkest {
    background-color: ${({ theme }) => theme.primary.colors.orange.darkest};
  }

  .box.orange--darker {
    background-color: ${({ theme }) => theme.primary.colors.orange.darker};
  }

  .box.orange--base {
    background-color: ${({ theme }) => theme.primary.colors.orange.base};
  }

  .box.orange--lighter {
    background-color: ${({ theme }) => theme.primary.colors.orange.lighter};
  }

  .box.orange--lightest {
    background-color: ${({ theme }) => theme.primary.colors.orange.lightest};
  }

  .box.orange--soft {
    background-color: ${({ theme }) => theme.primary.colors.orange.soft};
  }

  .box.red--darkest {
    background-color: ${({ theme }) => theme.primary.colors.red.darkest};
  }

  .box.red--darker {
    background-color: ${({ theme }) => theme.primary.colors.red.darker};
  }

  .box.red--base {
    background-color: ${({ theme }) => theme.primary.colors.red.base};
  }

  .box.red--lighter {
    background-color: ${({ theme }) => theme.primary.colors.red.lighter};
  }

  .box.red--lightest {
    background-color: ${({ theme }) => theme.primary.colors.red.lightest};
  }

  .box.red--soft {
    background-color: ${({ theme }) => theme.primary.colors.red.soft};
  }

  .box.gray--darkest {
    background-color: ${({ theme }) => theme.primary.colors.gray.darkest};
  }

  .box.gray--darker {
    background-color: ${({ theme }) => theme.primary.colors.gray.darker};
  }

  .box.gray--base {
    background-color: ${({ theme }) => theme.primary.colors.gray.base};
  }

  .box.gray--lighter {
    background-color: ${({ theme }) => theme.primary.colors.gray.lighter};
  }

  .box.gray--lightest {
    background-color: ${({ theme }) => theme.primary.colors.gray.lightest};
  }

  .box.gray--soft {
    background-color: ${({ theme }) => theme.primary.colors.gray.soft};
  }

  .box.black {
    background-color: ${({ theme }) => theme.primary.colors.black};
  }

  .box.white {
    background-color: ${({ theme }) => theme.primary.colors.white};
    border: 2px solid ${({ theme }) => theme.primary.colors.black};
  }

  .text-shadow-demo {
    ${textShadowMixin};
  }
`;

export default ThemePage;
